import { ReactComponent as Arrow } from 'assets/svg/arrow.svg'
import { StyledButton } from './Styled'

interface TP {
  onClick?: () => void
}

export const BackButton = ({ onClick }: TP) => {
  return (
    <StyledButton onClick={onClick}>
      <Arrow />
      <span>Назад</span>
    </StyledButton>
  )
}
