import { Context } from './context'
import { useEditor } from '@tiptap/react'
import Image from '@tiptap/extension-image'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import FontFamily from '@tiptap/extension-font-family'
import FontUnderline from '@tiptap/extension-underline'
import FontSubscript from '@tiptap/extension-subscript'
import FontSuperscript from '@tiptap/extension-superscript'
import Link from '@tiptap/extension-link'
import { storePage } from 'store/page'

import { TextSize } from './Extension/TextSize'
import { CustomTableCell } from './Extension/Table'
import { StyledExtension } from './Extension/Styled'

import { setSaveConntent } from './save'
import { StoreBlockParametrs } from 'store/blockParametrs'

interface TP {
  children: React.ReactNode
  editable?: boolean
}

export const Provider = ({ children, editable = true }: TP) => {
  const [content, text_block_id] = storePage((state) => [
    state.content,
    state.text_block_id,
  ])

  const blockParametrs = StoreBlockParametrs((state) => state)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Image,
      Table.configure({
        resizable: true,
      }),
      CustomTableCell,
      TableHeader,
      TableRow,
      Color,
      TextStyle,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextSize,
      FontFamily,
      FontUnderline,
      FontSubscript,
      FontSuperscript,
      Link,
      StyledExtension,
    ],
    content: content ?? '',
    editable,
    onUpdate({ editor }) {
      setSaveConntent(
        text_block_id!,
        editor.getHTML(),
        JSON.stringify(blockParametrs)
      )
    },
  })
  if (editor == null) {
    return null
  }

  return <Context.Provider value={{ editor }}>{children}</Context.Provider>
}
