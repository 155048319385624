import styled from 'styled-components'

export const EditorWrapUi = styled.div`
  background-color: #fbfbfb;
  display: flex;
  min-height: 100vh;
  position: relative;
`
export const EditorContentUi = styled.div<{
  active: boolean
}>`
  ${(props) => {
    return `
    width: ${props.active ? '100%' : 'calc(100% - 400px)'};
    transition: 0.3s;
    padding: 32px;
    @media (max-width: 768px) {
      width: 100%;
    }
  `
  }}
`
export const EditorAsideUi = styled.div<{
  active: boolean
  indentOff?: boolean
}>`
  .tabs {
    //padding: 10px 0;
  }
  .tab-wrap {
    padding: 16px;
    font-family: var(--font-sans);
    border-top: 1px solid #b0acb8;

    &:last-of-type {
      border-bottom: 1px solid #b0acb8;
    }
  }
  ${(props) => {
    return `
    width: 400px;
    box-shadow: 0px 4px 32px rgba(177, 177, 177, 0.25);
    // padding: ${props.indentOff ? 0 : '16px'};
    position: fixed;
    transition: 0.3s;
    right: ${props.active ? '0' : '-400px'};
    top: 0;
    background-color: #ffffff;
    height: 100%;
    z-index: 500;
    @media (max-width: 768px) {
      display: none;
    }
  `
  }}
`
export const EditorAsideTrigger = styled.div`
  width: 48px;
  height: 48px;
  background: #20bdd2;
  border-radius: 0px 0px 0px 16px;
  position: absolute;
  left: -48px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
