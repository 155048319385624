import React from 'react'
import type { RouteObject } from 'react-router-dom'

import { Loader } from 'components/ui'

const Editor = React.lazy(
  async () => await import('views/publisher/page/page/Item')
)

const routes: RouteObject[] = [
  {
    path: '/publisher/:book/:page',
    element: (
      <React.Suspense fallback={<Loader />}>
        <Editor />
      </React.Suspense>
    ),
  },
]

export default routes
