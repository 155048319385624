import {
  HeaderCabinet,
  HeaderCenter,
  HeaderLogo,
  HeaderLogOut,
  HeaderStyles,
  HeaderUser,
  HeaderWrap,
} from './styled'

import { ReactComponent as Logo } from 'assets/img/main-logo.svg'
import { ReactComponent as User } from 'assets/svg/icon-user.svg'
import { ReactComponent as Logout } from 'assets/svg/icon-logout.svg'

export const Header = () => {
  return (
    <HeaderStyles>
      <HeaderWrap>
        <HeaderLogo to="/">
          <Logo />
        </HeaderLogo>

        <HeaderCenter>
          <div className="title">Кабинет издателя</div>
        </HeaderCenter>

        <HeaderCabinet>
          <HeaderUser to="/lk">
            <div className="name">
              Александров Васисуалий <br /> Вениаминович
            </div>
            <User className="icon" />
          </HeaderUser>
          <HeaderLogOut>
            <Logout />
          </HeaderLogOut>
        </HeaderCabinet>
      </HeaderWrap>
    </HeaderStyles>
  )
}
