import { Link } from 'react-router-dom'

export const NotFound: React.FC = () => {
  return (
    <div>
      <h2>404</h2>
      <Link to="/">На главную</Link>
    </div>
  )
}
