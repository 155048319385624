import ReactDOM from 'react-dom/client'
import { Router } from 'core'
import { Styled } from 'modules'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import Modal from 'react-modal'
import './reset.scss'
import 'config'

Modal.setAppElement('#root')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <Styled>
      <Router />
    </Styled>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
