import { useState } from 'react'
import type { ReactNode } from 'react'

import {
  EditorAsideTrigger,
  EditorAsideUi,
  EditorContentUi,
  EditorWrapUi,
} from './Styled'
import { ReactComponent as IconEditorAsideTrigger } from 'assets/svg/IconEditorAsideTrigger.svg'

interface TOffCanvas {
  content: ReactNode
  menu: ReactNode
}

export const OffCanvas = ({ content, menu }: TOffCanvas) => {
  const [showAsideUi, setShowAsideUi] = useState(true)

  return (
    <EditorWrapUi>
      <EditorContentUi active={!showAsideUi}>{content}</EditorContentUi>
      <EditorAsideUi active={showAsideUi}>
        <EditorAsideTrigger
          onClick={() => {
            setShowAsideUi(!showAsideUi)
          }}
        >
          <IconEditorAsideTrigger />
        </EditorAsideTrigger>
        {menu}
      </EditorAsideUi>
    </EditorWrapUi>
  )
}
