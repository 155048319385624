import axios from 'axios'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import { authApi } from 'api/auth'
import { getToken, setToken, removeToken } from 'helpers/localStorage'

const axiosSetToken = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

const initToken = (): string => {
  const token = getToken()
  if (token) {
    axiosSetToken(token)
  }
  return token ?? ''
}

const addToken = (token: string) => {
  setToken(token)
  axiosSetToken(token)
}

const deleteToken = () => {
  removeToken()
  axios.defaults.headers.common.Authorization = ''
}

interface TConfig {
  token: string | null
  loading: boolean
  user: TUser | null
  signIn: (user: TLogin, failCallback: (message: string) => void) => void
  setToken: (token: string) => void
  getUser: () => void
  signOut: () => void
}

export const useAuthStore = create<TConfig>((set) => ({
  token: initToken(),
  user: null,
  loading: false,
  signIn: (form, failCallback) => {
    set({ loading: true })
    authApi
      .getToken(form)
      .then(({ data }) => {
        const token = data?.access_token?.token
        addToken(token)
        set({ token, user: data.data })
      })
      .catch(({ response }) => {
        failCallback(response?.data?.message ?? '')
      })
      .finally(() => {
        set({ loading: false })
      })
  },
  setToken: (token) => {
    addToken(token)
    set({ token })
  },
  getUser: () => {
    authApi
      .getUser()
      .then(({ data }) => {
        set({ user: data.data })
      })
      .catch(() => {
        deleteToken()
        set({ token: '', user: null })
      })
  },
  signOut: () => {
    deleteToken()
    set({ token: '', user: null })
  },
  shallow,
}))
