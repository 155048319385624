import { EditorContent } from '@tiptap/react'
import { useEditor } from 'modules/Editor/Context'
import { BlockUi } from './Styled'
import { StoreBlockParametrs } from 'store/blockParametrs'
import { StyleWrap } from 'components/editor'
import { storePanel } from 'store/panel'

export const Block = () => {
  const [style] = storePanel((state) => [state.style])
  const blockParametrs = StoreBlockParametrs((state) => state)

  const { editor } = useEditor()
  const handle = (target: any) => {
    if (target?.classList?.contains('modules-editor')) {
      editor.commands.focus('end')
    }
  }

  return (
    <BlockUi
      width={blockParametrs.width}
      padding={blockParametrs.padding}
      margin={blockParametrs.margin}
      position={blockParametrs.position}
      className={`width-${blockParametrs.width}`}
      background={blockParametrs.background}
    >
      <div
        onClick={({ target }) => {
          handle(target)
        }}
      >
        <StyleWrap activeStyles={style[0]}>
          <EditorContent editor={editor} />
        </StyleWrap>
      </div>
    </BlockUi>
  )
}
