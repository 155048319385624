import { type ReactElement } from 'react'
import { ButtonStyled } from './Styled'

interface TP {
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: () => void
  children: string | ReactElement
  variant?: string
  className?: string
}

export const Button = ({
  type = 'button',
  children,
  onClick,
  variant,
  className,
}: TP) => {
  return (
    <ButtonStyled
      type={type}
      onClick={onClick}
      variant={variant}
      className={className}
    >
      {children}
    </ButtonStyled>
  )
}
