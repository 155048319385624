import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'
const LoginPage = lazy(async () => await import('./page/Login'))

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginPage />,
  },
]

export default routes
