import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const HeaderLogOut = styled.button`
  width: 40px;
  height: 40px;

  svg {
    width: 40px;
    height: 40px;
  }
`

export const HeaderUser = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  .name {
    font-family: var(--font-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: right;
  }

  .icon {
    width: 40px;
    height: 40px;
    margin-left: 12px;
  }
`

export const HeaderCabinet = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderCenter = styled.div`
  margin: 0 24px;
  .title {
    font-family: var(--font-sans);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
`

export const HeaderLogo = styled(Link)``

export const HeaderWrap = styled.div`
  max-width: 1696px;
  margin: 0 auto;
  padding: 26px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderStyles = styled.header`
  background-color: var(--cl-white);
  box-shadow: 0px 4px 32px 0px rgba(177, 177, 177, 0.25);
`
