import styled, { css } from 'styled-components'

interface TWrap {
  activeStyles: TStyle
  children: React.ReactNode
}

const decoration = {
  strike: 'line-through',
  underline: 'underline',
}

const getDecoration = (style: TStyle) =>
  (Object.keys(decoration) as Array<keyof typeof decoration>)
    .filter((item) => style[item])
    .map((item) => decoration[item])
    .join(' ') ?? 'auto'

const vertical = {
  subscript: 'sub',
  superscript: 'super',
}

const getVertical = (style: TStyle) =>
  (Object.keys(vertical) as Array<keyof typeof vertical>)
    .filter((item) => style[item])
    .map((item) => vertical[item])
    .join(' ') ?? 'baseline'

const Wrap = styled.div<{ activeStyles: TStyle }>`
  ${(props) => {
    const {
      activeStyles: { fontSize, textIndent, letterSpacing, lineHeight },
    } = props
    return css`
      font-size: ${fontSize === 0 ? 'initial' : fontSize};
      text-indent: ${textIndent === 0 ? 'initial' : fontSize};
      letter-spacing: ${letterSpacing === 0 ? 'initial' : fontSize};
      line-height: ${lineHeight === 0 ? 'initial' : fontSize};
    `
  }}

  margin-bottom: ${(props) => props.activeStyles.marginBotton};

  font-family: ${(props) => props.activeStyles.fontFamily};
  font-style: ${(props) => (props.activeStyles.italic ? 'italic' : 'normal')};
  font-weight: ${(props) => (props.activeStyles.bold ? 'bold' : 'normal')};
  text-decoration: ${(props) => getDecoration(props.activeStyles)};
  span {
    vertical-align: ${(props) => getVertical(props.activeStyles)};
  }
`

export const StyleWrap = ({ activeStyles, children }: TWrap) => {
  return activeStyles ? ( //
    <Wrap activeStyles={activeStyles}>{children}</Wrap>
  ) : (
    <div>{children}</div>
  )
}
