import { Navigate, useLocation } from 'react-router-dom'
import { useAuthStore } from 'store/auth'

interface TP {
  children: React.ReactNode
}

export const AuthRouter = ({ children }: TP) => {
  const user = useAuthStore((state) => state.user)
  const location = useLocation()
  const isLoginPage = location.pathname.includes('login')

  if (user == null && !isLoginPage) {
    return <Navigate to="/login" replace={true} />
  }

  if (user != null && isLoginPage) {
    return <Navigate to="/" replace={true} />
  }

  return <>{children}</>
}
