const key_token = 'api_token'

export const getToken = (): string => {
  return localStorage.getItem(key_token) ?? ''
}

export const setToken = (token: string) => {
  localStorage.setItem(key_token, token)
}

export const removeToken = () => {
  localStorage.removeItem(key_token)
}
