import { blockApi } from 'api/block'
const delay = 1000
let timer: any

export const setSaveConntent = (
  text_block_id: number,
  content: string,
  blockParametrs: string
) => {
  clearTimeout(timer)
  timer = setTimeout(() => {
    blockApi.update(text_block_id, { content, settings: blockParametrs })
  }, delay)
}
