import axios, { type AxiosResponse } from 'axios'
import { getRoute } from 'helpers/routes'

interface TP {
  data: {
    id: number
  }
}

interface TAP {
  store: (bookId: number | string, data: any) => Promise<AxiosResponse<TP, any>>
  reset: (bookId: number | string) => Promise<AxiosResponse<TP, any>>
}

export const styleApi: TAP = {
  async store(bookId, data) {
    return await axios.post<TP>(
      getRoute('api.publisher.book.style-preset.store', bookId),
      { settings: JSON.stringify(data) }
    )
  },
  async reset(bookId: number | string) {
    return await axios.get<TP>(
      getRoute('api.publisher.book.style-preset.clear', bookId)
    )
  },
}
