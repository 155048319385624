import { create } from 'zustand'

interface PaddingType {
  top: number
  bottom: number
  left: number
  right: number
}

interface MarginType {
  top: number
  bottom: number
  left: number
  right: number
}

interface PositionType {
  x: string
  y: string
}

interface BlockParametrsType {
  width: number
  position: PositionType
  padding: PaddingType
  margin: MarginType
  background: string
  setWidth: any
  setPadding: any
  setMargin: any
  setPosition: any
  setBackground: any
  resetParams: () => void
}

const initialState = {
  width: 100,
  position: { x: 'left', y: 'top' },
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  margin: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  background: '#FFFFFF',
}

export const StoreBlockParametrs = create<BlockParametrsType>()((set) => ({
  ...initialState,
  setWidth: (val: number) => {
    set((state) => ({ width: val }))
  },
  setPadding: (padding: PaddingType) => {
    set((state) => ({ padding }))
  },
  setMargin: (margin: MarginType) => {
    set((state) => ({ margin }))
  },
  setPosition: (position: PositionType) => {
    set((state) => ({ position }))
  },
  setBackground: (background: string) => {
    set((state) => ({ background }))
  },
  resetParams: () => {
    set(initialState)
  },
}))
