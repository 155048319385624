import styled from 'styled-components'

export const Button = styled.button`
  width: 24px;
  height: 24px;
  color: #20bdd2;
  margin-left: auto;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const Text = styled.div`
  font-size: 13px;
  line-height: 100%;
  margin-right: 10px;
`

export const ClipBoardStyled = styled.div`
  .wrap {
    display: flex;
    align-items: center;
  }
`
