import styled from 'styled-components'

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 16px;
  color: #20bdd2;
  width: 100%;

  span {
    font-family: var(--font-sans);
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
  }

  svg {
    width: 24px;
    height: 16px;
    margin-right: 8px;
  }
`
