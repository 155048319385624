import { mergeAttributes, Node } from '@tiptap/core'
import { StyleWrap } from 'components/editor'
import {
  ReactNodeViewRenderer,
  NodeViewWrapper,
  NodeViewContent,
} from '@tiptap/react'
import { storePanel } from 'store/panel'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    reactComponent: {
      addStyledComponent: (styleId: string) => ReturnType
    }
  }
}

const tagName = 'react-style'

const Component = (props: any) => {
  const style = storePanel((state) => state.style)
  const activeStyles = style.find(
    (item) => item.id === String(props.node.attrs.styleId)
  )!
  return (
    <NodeViewWrapper>
      <StyleWrap activeStyles={activeStyles}>
        <NodeViewContent />
      </StyleWrap>
    </NodeViewWrapper>
  )
}

export const StyledExtension = Node.create({
  name: 'reactStyle',

  group: 'block',

  content: 'inline*',

  addAttributes() {
    return {
      styleId: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: tagName,
      },
    ]
  },

  addCommands() {
    return {
      addStyledComponent:
        (styleId) =>
        ({ commands }) => {
          return commands.setNode(this.name, { styleId })
        },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return [tagName, mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component)
  },
})
