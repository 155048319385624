import React from 'react'
import type { RouteObject } from 'react-router-dom'
import { Loader } from 'components/ui'

const Page = React.lazy(async () => await import('views/user/page/Item'))

const routes: RouteObject[] = [
  {
    path: '/user',
    element: (
      <React.Suspense fallback={<Loader />}>
        <Page />
      </React.Suspense>
    ),
  },
]

export default routes
