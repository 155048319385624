import styled, { css } from 'styled-components'

export const BlockUi = styled.div<{
  width: number
  position: { x: string; y: string }
  padding: {
    top: number
    bottom: number
    left: number
    right: number
  }
  margin: {
    top: number
    bottom: number
    left: number
    right: number
  }
  background: string
}>`
  box-sizing: border-box;
  ${(props) => {
    let left = '0px'
    let right = 'auto'
    let top = '0px'
    let bottom = 'autop'
    let transformX = 'translateX(0)'
    let transformY = 'translateY(0)'
    if (props.position.x === 'left') {
      left = '0px'
      right = 'auto'
      transformX = 'translateX(0)'
    }
    if (props.position.x === 'center') {
      left = '50%'
      right = 'auto'
      transformX = 'translateX(-50%)'
    }
    if (props.position.x === 'right') {
      left = 'auto'
      right = '0px'
      transformX = 'translateX(0)'
    }
    if (props.position.y === 'top') {
      top = '0px'
      bottom = 'auto'
      transformY = 'translateY(0)'
    }
    if (props.position.y === 'center') {
      top = '50%'
      bottom = 'auto'
      transformY = 'translateY(-50%)'
    }
    if (props.position.y === 'bottom') {
      top = 'auto'
      bottom = '0px'
      transformY = 'translateY(0)'
    }
    return css`
      padding-top: ${props.padding.top || 0}px;
      padding-bottom: ${props.padding.bottom || 0}px;
      padding-left: ${props.padding.left || 0}px;
      padding-right: ${props.padding.right || 0}px;
      margin-top: ${props.position.y === 'top' ? props.margin.top : 0}px;
      margin-bottom: ${props.position.y === 'bottom'
        ? props.margin.bottom
        : 0}px;
      margin-left: ${props.position.x === 'left' ? props.margin.left : 0}px;
      margin-right: ${props.position.y === 'right' ? props.margin.right : 0}px;
      background-color: ${props.background};
      box-shadow: 0px 4px 32px rgba(177, 177, 177, 0.25);
      border-radius: 2px;
      max-width: 100%;
      position: absolute;
      left: ${left};
      right: ${right};
      top: ${top};
      bottom: ${bottom};
      transform: ${transformX} ${transformY};
      width: 100%;

      // max-width: 800px;
      min-height: 300px;
      padding: 32px;
      margin: 60px 0;

      display: flex;
      flex-direction: column;
      * {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: auto;
      }

      &.width-100 {
        width: 100%;
      }
      &.width-66 {
        width: 66%;
        @media (max-width: 768px) {
          width: 75%;
        }
        @media (max-width: 640px) {
          width: 87%;
        }
        @media (max-width: 375px) {
          width: 100%;
        }
      }
      &.width-50 {
        width: 50%;
        @media (max-width: 768px) {
          width: 66%;
        }
        @media (max-width: 640px) {
          width: 75%;
        }
        @media (max-width: 375px) {
          width: 100%;
        }
      }
      &.width-33 {
        width: 33%;
        @media (max-width: 768px) {
          width: 50%;
        }
        @media (max-width: 640px) {
          width: 66%;
        }
        @media (max-width: 375px) {
          width: 100%;
        }
      }
    `
  }}
`
