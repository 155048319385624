import React from 'react'
import type { RouteObject } from 'react-router-dom'

import { Loader } from 'components/ui'

const Editor = React.lazy(async () => await import('views/book/page/Item'))

const name = 'book'

const routes: RouteObject[] = [
  {
    path: `/${name}/:id`,
    element: (
      <React.Suspense fallback={<Loader />}>
        <Editor />
      </React.Suspense>
    ),
  },
]

export default routes
