import { create } from 'zustand'

interface TInit {
  book_id: number | null
  text_block_id: number | null
  content: string
  settings: string
  loading: boolean
}

interface TPage extends TInit {
  setContent: (content: string) => void
  setBookId: (id: number) => void
  setSettings: (settings: string) => void
  setLoading: (loading: boolean) => void
  setTextBlockId: (number: number) => void
  resetStorePage: () => void
}

const initialState: TInit = {
  book_id: null,
  text_block_id: null,
  content: '',
  settings: '',
  loading: true,
}

export const storePage = create<TPage>()((set) => ({
  ...initialState,
  setContent: (content: string) => {
    set({ content })
  },
  setSettings: (settings: string) => {
    set({ settings })
  },
  setBookId: (book_id: number) => {
    set({ book_id })
  },
  setLoading: (loading: boolean) => {
    set({ loading })
  },
  setTextBlockId: (text_block_id: number) => {
    set({ text_block_id })
  },
  resetStorePage: () => {
    set(initialState)
  },
}))
