import { Block } from 'modules/Editor/Components/Block'

import './style.scss'

export const Main: React.FC = () => {
  return (
    <div className="modules-editor">
      <Block />
    </div>
  )
}
