import { ThemeProvider } from 'styled-components'
import { theme } from './theme'
import GlobalStyle from './globalStyles'

interface TStyled {
  children: React.ReactNode
}

export const Styled = ({ children }: TStyled) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}
