import styled, { css } from 'styled-components'

export const ButtonStyled = styled.button<{ variant?: string | undefined }>`
  background-color: #20bdd2;
  border-radius: 4px;
  border: 1px solid #20bdd2;
  font-size: 16px;
  line-height: 100%;
  color: #fbfbfb;
  padding: 12px;
  transition: background-color 0.3s linear;

  &:hover:not(:active) {
    background-color: #1995a2;
  }

  ${(props) =>
    props?.variant === 'empty' &&
    css`
      background-color: #fff;
      color: #20bdd2;
      &:hover:not(:active) {
        background-color: #f4f4f4;
      }
    `}
`
