import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  
  .grid {
    display: flex;
    margin: -16px;
    align-items: start;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }
    
    .grid-item {
      //max-width: 14.29%;
      //padding: 16px;
    }
  }
  
  .ReactModal__Overlay {
    min-height: 100vh;
    max-height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, .2) !important;
  }
`

export default GlobalStyle
