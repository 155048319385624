import { useEffect } from 'react'
import { useAuthStore } from 'store/auth'

export const GetProfile = () => {
  const getUser = useAuthStore((state) => state.getUser)

  useEffect(() => {
    getUser()
  }, [getUser])

  return null
}
