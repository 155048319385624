import React from 'react'
import type { RouteObject } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import { Loader } from 'components/ui'

const Publisher = React.lazy(
  async () => await import('views/publisher/book/page/Publisher')
)
const Chapters = React.lazy(
  async () => await import('views/publisher/book/page/Chapters')
)

const name = 'publisher'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={`/${name}`} />,
  },
  {
    path: `/${name}`,
    element: (
      <React.Suspense fallback={<Loader />}>
        <Publisher />
      </React.Suspense>
    ),
  },
  {
    path: `/${name}/:book_id`,
    element: (
      <React.Suspense fallback={<Loader />}>
        <Chapters />
      </React.Suspense>
    ),
  },
]

export default routes
