import { useRoutes } from 'react-router-dom'

import baseRoutes from 'generated/routes'
import { NotFound } from 'components/pages'
import { AuthRouter, GetProfile } from './components'
import { useAuthStore } from 'store/auth'

const routes = [...baseRoutes, { path: '*', element: <NotFound /> }]

export const Router: React.FC = () => {
  const [token, user] = useAuthStore((state) => [state.token, state.user])
  const element = useRoutes(routes)

  if (token && user == null) {
    return <GetProfile />
  }

  return (
    <AuthRouter>
      <div>{element}</div>
    </AuthRouter>
  )
}
