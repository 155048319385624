import { Button, ClipBoardStyled, Text } from './Styled'
import { ReactComponent as Copy } from './copy.svg'

export const Clipboard = ({
  content,
  className,
}: {
  content: string
  className?: string
}) => {
  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(content)
  }

  return (
    <ClipBoardStyled className={className}>
      <div className="wrap">
        <Text>{content}</Text>
        <Button onClick={handleCopyClick}>
          <Copy />
        </Button>
      </div>
    </ClipBoardStyled>
  )
}
