import { create } from 'zustand'
import { styleApi } from 'api/style'

const tabs = [
  'text',
  'styleEdit',
  'styleAdd',
  'page',
  'button',
  'image',
  'table',
  'reset',
  'colorEdit',
  'colorAdd',
] as const

export const fontList = [
  {
    value: 'Georgia, serif',
    label: 'Georgia, serif',
  },
  {
    value: '"Gill Sans", sans-serif',
    label: '"Gill Sans", sans-serif',
  },
  {
    value: 'sans-serif',
    label: 'sans-serif',
  },
  {
    value: 'system-ui',
    label: 'system-ui',
  },
] as const

export type TStyleList = TStyle[]
export type TStyleInput = Omit<TStyle, 'id'>

const saveStyle = (bookId: number, styleList: TStyleInput): any =>
  styleApi.store(bookId, styleList)
const serverResponseEdit = (style: TStyleServer[]): TStyleList =>
  style.map((item) => ({ id: String(item.id), ...JSON.parse(item.settings) }))

export type TTabs = typeof tabs

export interface TPanel {
  tabs: TTabs
  activeTab: TTabs[number]
  setActiveTab: (activeTab: TTabs[number]) => void

  style: TStyleList
  defaultStyle: TStyleList
  editStyle: TStyleList[number] | null
  getStyle: (style: TStyleServer[]) => void
  updateStyle: (styleId: TStyle['id'], styleField: TStyleList[number]) => void
  setStyle: (styleId: TStyle['id'], styleField: TStyleList[number]) => void
  addStyle: (style: TStyleInput) => void
  setEditStyle: (editStyle: TStyleList[number]) => void

  book_id: number | null
  setBookIdFromStyle: (id: number) => void
}

export const storePanel = create<TPanel>()((set, get) => ({
  style: [],
  defaultStyle: [],
  activeTab: 'text',
  tabs,
  editStyle: null,
  book_id: null,
  getStyle: (style) => {
    const list = serverResponseEdit(style)
    set({ style: list, defaultStyle: list })
  },
  updateStyle: (styleId, styleField) => {
    set((state) => {
      const newList = state.style.map((item) =>
        item.id === styleId ? styleField : item
      )
      return { style: newList }
    })
  },
  setStyle: (styleId, styleField) => {
    set((state) => {
      const newList = state.style.map((item) =>
        item.id === styleId ? styleField : item
      )
      // saveStyle(state.book_id!, newList)
      return { style: newList }
    })
  },
  addStyle: (style) => {
    saveStyle(get().book_id!, style).then(({ data }: any) => {
      const list = serverResponseEdit(data.data ?? [])
      set({ style: list, defaultStyle: list })
    })
  },
  setActiveTab: (activeTab) => {
    set((state) => ({
      activeTab,
      style: state.defaultStyle,
    }))
  },
  setEditStyle: (editStyle) => {
    set(() => ({ editStyle }))
  },
  setBookIdFromStyle: (book_id) => {
    set({ book_id })
  },
}))
