import axios, { type AxiosResponse } from 'axios'
import { getRoute } from 'helpers/routes'

interface TP {
  data: {
    id: number
    content: string
    type: string
    settings?: string
  }
}

interface TInput {
  content?: string
  settings?: string
}

interface TCreate extends TInput {
  type: string
}

interface TAP {
  create: (
    id: number | string,
    data: TCreate
  ) => Promise<AxiosResponse<TP, any>>
  update: (
    text_block_id: number | string,
    data: TInput
  ) => Promise<AxiosResponse<TP, any>>
}

export const blockApi: TAP = {
  async create(pageId, data) {
    return await axios.post<TP>(
      getRoute('api.publisher.page.block.store', pageId),
      data
    )
  },
  async update(text_block_id, data) {
    console.log('data', data)
    return await axios.put<TP>(
      getRoute('api.publisher.page.block.update', text_block_id),
      data
    )
  },
}
