// genered
import type { RouteObject } from 'react-router-dom'
import authRoutes from '../views/auth/routes'
import bookRoutes from '../views/book/routes'
import publisherbookRoutes from '../views/publisher/book/routes'
import publisherpageRoutes from '../views/publisher/page/routes'
import userRoutes from '../views/user/routes'

const routes: RouteObject[] = [
  ...authRoutes,
  ...bookRoutes,
  ...publisherbookRoutes,
  ...publisherpageRoutes,
  ...userRoutes,
]

export default routes
